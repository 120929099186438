// core-js polyfills
// These are needed for anything in tsconfig.base.js's "lib" section, other than "DOM" and the ES version that matches the "target".
// To figure out what imports you need for a tsconfig "lib" declaration, check the tsconfig documentation to see what functions are defined.
// Please also comment each import here with the corresponding "lib" declaration.
import 'core-js/proposals/array-flat-map'; // ES2019.Array
import 'core-js/actual/set-immediate'; // Not part of official specs - declared by pure.d.ts instead

// Import jquery and put it onto the window for all those libraries that depend on referencing $/jQuery directly on window (rather than imports).
import $ from 'jquery';
(window as any).$ = $;
(window as any).jQuery = $;

// Angular dependencies
import 'zone.js';
import '@angular/localize/init'; // Required by NgBootstrap: https://ng-bootstrap.github.io/#/guides/i18n

// Define window.process since a module we use (lru-cache?) accidentally depends on this reference
Object.assign(window, {
    process: {
        env: { DEBUG: undefined },
    },
});
